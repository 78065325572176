
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});
    const characteristicEditor = ref();
    const specificationEditor = ref();
    const descriptionEditor = ref();
    const productAttributeValues = ref([]);
    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" }
    ];

    const gridOptions: GridOptions = {
      title: "產品資料",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "獎項清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "獎項清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Category.Name",
          title: "抽獎分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },

        // {
        //   field: "Brand.Name",
        //   title: "品牌",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        // {
        //   field: "Country.Name",
        //   title: "產地",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("product/query", params)
          : undefined,
        queryAll: model
          ? params => model.dispatch("product/query", params)
          : undefined,
        save: model
          ? params => model.dispatch("product/save", params)
          : undefined
      },
      modalConfig: { width: "500", showFooter: true }
    };
    const formOptions = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "CategoryId",
          title: "類別",
          span: 24,
          slots: { default: "column-category-id" }
        },
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Picture.Id",
          title: "照片",
          span: 24,
          slots: { default: "column-picture" }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 24,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        }
        // {
        //   field: "Introduction",
        //   title: "商品簡述",
        //   span: 24,
        //   itemRender: {
        //     name: "$textarea",
        //     props: { placeholder: "請輸入商品簡述" }
        //   }
        // }
        // {
        //   field: "StartDate",
        //   title: "開始日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "請輸入日期" }
        //   }
        // },
        // {
        //   field: "EndDate",
        //   title: "結束日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "請輸入日期" }
        //   }
        // }
      ],
      rules: {
        Name: [{ required: true }],
        CategoryId: [{ required: true }]
      }
    };

    const categorySelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Ordinal",
          title: "順序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }]
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categorySelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      sliderWidth: ref("100%"),
      pictureFormOptions,
      showAttribute: ref(true),
      showAlbum: ref(true),
      showDescription: ref(true),
      showSpecification: ref(true),
      showCharacteristic: ref(true),
      characteristicEditor,
      specificationEditor,
      descriptionEditor,
      productAttributeValues
    };
  },
  methods: {
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;
      console.log(
        "🚀 ~ file: Main.vue ~ line 1091 ~ onGridEdit ~ entity",
        entity
      );
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = this.grid.editingRow.PictureAlbumId;
      callback();
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch("picture/save", params).then(
        () => {
          this.$model
            .dispatch("pictureAlbum/find", this.grid.editingRow.PictureAlbumId)
            .then(
              entity => {
                entity.Pictures.forEach((picture: any) => {
                  delete picture.$id;
                  delete picture.Album;
                });
                const sliderEditor: any = this.$refs.sliderEditor;
                sliderEditor.reload(
                  entity.Pictures.sort(
                    (a: any, b: any) => a.Ordinal - b.Ordinal
                  )
                );
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        },
        reason => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    }
  }
});
